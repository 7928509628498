.signin-page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    url("../../../public/files/wedding/izumrud/login-bg.jpg");
  background-size: cover;
  background-position: center;
  .signin-content {
    background-color: white;
    padding: calc(15px + 1%);
    width: clamp(200px, 80%, 400px);
    border-radius: 20px;
    input {
      width: 100%;
    }
  }
  img {
    width: 50%;

    margin: 0 auto 20px;
    display: block;
  }
}
