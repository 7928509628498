.header {
  padding: 0;
  padding: 0 5vw;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: 100%;
    padding: 10px;
  }
  .button {
    display: none;
    font-size: 16px;
  }
  @media (max-width: 1000px) {
    .button {
      display: inline;
    }
  }
}
