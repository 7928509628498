.service-selector-container {
   .service-container {
    background-color: #ebe2d3;
    padding: 5px;
    max-height: 50vh;
    overflow-y: auto;
  }

  .service-card {
    background-color: white;
    margin-top: 5px;
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}
