.ant-menu{
    background: transparent !important;
}



.demo-logo-vertical{  
   height: 60px;
}


:where(.css-dev-only-do-not-override-49qm).ant-menu-light .ant-menu-item-selected{
    background: rgba(255, 255, 255, 0.477);
}