@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

body {
}

/* Styling the scrollbar container */
::-webkit-scrollbar {
  width: 10px !important; /* Width of the scrollbar */
  height: 10px !important; /* Height of the scrollbar (horizontal scrollbar) */
}

/* Styling the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light background color */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Styling the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Darker color for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Optional border around the thumb */
}

/* Styling the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker thumb on hover */
}

/* Optional: Hide the scrollbar if not hovered */
::-webkit-scrollbar {
  opacity: 0;
  transition: opacity 0.3s;
}

:hover::-webkit-scrollbar {
  opacity: 1;
}

.antd-input-style {
  padding: 6px 10px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  transition: 0.2s;
  outline: 3px solid rgba(255, 0, 0, 0);

  &:hover,
  &:focus {
    border-color: #ab5b0e;
  }

  &:focus {
    outline-color: rgba(255, 0, 0, 0.05);
  }
}
