.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(255,255,255, .85),
      rgba(255,255,255, .85)
    ),
    url("../../../../public/files/wedding/izumrud/login-bg.jpg");
  background-position: center;
  background-size: cover;
  img {
    width: clamp(100px, 50vw, 200px);
    margin-bottom: 30px;
  }
}
