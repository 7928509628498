.cr-user-info {
  background-color: transparent;
  padding: 7px 12px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 71px;
  }

  & .ant-dropdown-link {
    color: inherit;

    & .anticon {
      font-size: 12px;
    }
  }

  &.light {
    & .ant-dropdown-link {
      color: inherit;
    }

    & .cr-user-name {
      color: inherit;
    }
  }
}

.cr-user-info-inner {
  display: flex;
  align-items: center;
}

.cr-user-info-avatar {
  font-size: 24px;
  background-color: orange;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cr-user-info-content {
  min-width: 130px;
  margin-left: 16px;

  // [dir=rtl] & {
  //   margin-left: 0;
  //   margin-right: 16px;
  // }
}

.cr-user-name-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cr-user-name {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: inherit;

  &.light {
    color: inherit;
  }
}

.cr-user-arrow {
  margin-left: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: 12px;
  }

  & svg {
    display: block;
  }
}

.cr-user-designation {
  margin-top: -2px;
  color: inherit;

  .ant-layout-sider-dark & {
    color: inherit;
  }
}

.dropdown-list {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  & .ant-list-item {
    padding: 5px 12px;
    cursor: pointer;

    &:hover {
      background-color: rgba(235, 226, 211, 0.3);
      color: #ab5b0e;
    }
  }
}

.cr-user-info-hasColor {
  & .ant-dropdown-link {
    color: inherit;
  }

  &.light {
    & .ant-dropdown-link {
      color: inherit;
    }

    & .cr-user-name {
      color: inherit;
    }
  }

  & .cr-user-designation {
    color: inherit;

    .ant-layout-sider-dark & {
      color: inherit;
    }
  }

  & .cr-user-name {
    color: inherit;

    &.light {
      color: inherit;
    }
  }
}
