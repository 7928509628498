.reservation-calendar-container {
  .rbc-toolbar {
    gap: 10px;
  }
  .rbc-btn-group {
    display: flex;
    flex-wrap: wrap;
  }
  .rbc-toolbar {
    .rbc-btn-group:first-child button {
      font-size: 17px;
      padding: 5px 20px;
    }
    .rbc-btn-group:last-child button {
      font-size: 17px;
      padding: 5px 10px;
    }
    button {
      padding: 5px;
    }
  }
  .rbc-toolbar button:active:hover,
  .rbc-toolbar button:active:focus,
  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus,
  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: #ab5b0e;
    color: rgb(246 233 220);
    border-color: #ab5b0e;
    box-shadow: none;
  }
  .rbc-event {
    background-color: #ab5b0e;
    // outline-color: #ff7b00;
    &:focus,
    &:active,
    &:hover {
      outline: 1px solid #ff7b00;
    }
  }

  .rbc-day-slot .rbc-event {
    border: 1px solid #ff7b00;
  }

  .rbc-today {
    background-color: rgba(255, 123, 0, 0.1);
  }

  .rbc-show-more {
    color: #ab5b0e;
    &:hover {
      color: #ff7b00;
    }
  }
  @media (max-width: 767px) {
    .rbc-toolbar {
      flex-direction: column-reverse;
    }
  }
}
