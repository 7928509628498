.user-profile-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
}

.user-profile-tabs {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 700px) {
    flex-direction: row;
  }

   & .ant-tabs-nav {
    min-width: 200px;
    // background-color: @component-background;
    // border-radius: @card-radius;
    padding: 20px 0;

    // @media screen and (min-width: @screen-xl) {
    //   min-width: 280px;
    // }

    background-color: white;
    border-radius: 20px;

    & .ant-tabs-tab {
      // color: @text-color;
      display: flex;
      align-items: center;
      padding: 12px 24px;
      margin-right: 20px !important;
      border-radius: 0 30px 30px 0;

      &:hover, &:focus {
        background-color: transparent;
        // color: @primary-color;
      }

      [dir=rtl] & {
        margin-right: 0 !important;
        margin-left: 20px !important;
        border-radius: 30px 0 0 30px;
      }

      & + .ant-tabs-tab {
        margin-top: 1px;
      }
    }

    & .ant-tabs-tab-active {
       background-color: rgba(171, 90, 14, 0.1);

      &:hover, &:focus {
       background-color: rgba(171, 90, 14, 0.1);
      }
    }

    & .ant-tabs-ink-bar {
      display: none;
    }
  }


  &.ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane,
  &.ant-tabs-left
    > div
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 0;
   }

  & .user-profile-icon {
    display: flex;
    align-items: center;

    & .icon {
      font-size: 20px;
      margin-right: 16px;

      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  & .ant-tabs-content-holder {
    padding-top: 20px;
    border-left: none !important;

    [dir="rtl"] & {
      order: 2;
    }

    @media screen and (min-width: 700px) {
      padding-left: 20px;
      padding-top: 0;

      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 20px;
      }
    }

    // @media screen and (min-width: $screen-xl) {
    //   padding-left: 32px;

    //   [dir="rtl"] & {
    //     padding-left: 0;
    //     padding-right: 32px;
    //   }
    // }
  }

  & .ant-tabs-content {
    background-color: white;
    border-radius: 20px;
    height: 100%;
    padding: 20px;

    & .ant-form-item {
      margin-bottom: 16px;
    }

    & .user-profile-group-btn {
      margin-bottom: 0;
    }
  }
}

.user-profile-group-btn {
  position: relative;

  & .ant-btn {
    & + .ant-btn {
      margin-left: 12px;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }
}

.user-profile-form {
  position: relative;
}

.user-profile-form-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.notification-list {
  position: relative;
}

.notification-list-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  & .label {
    margin-left: 15px;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}

.profile-notification {
  position: relative;

  & + .profile-notification {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;
    // border-top: $border-width-base $border-style-base $border-color-base;
  }
}
